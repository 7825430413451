[aria-label="Experts slides light"] .splide__arrows {
  display: none;
}

[aria-label="Experts slides"] .splide__arrows {
  display: none;
}

[aria-label="Media slides"] .splide__arrows {
  display: none;
}

[aria-label="Discount banner"] .splide__arrows {
  display: none;
}

[aria-label="Blog recommendation banner"] .splide__arrows {
  display: none;
}

[aria-label="Blog recommendation banner"] .splide__list li:first-child {
  margin-left: 16px;
}

@media screen and (min-width: 1024px) {
  [aria-label="Experts slides light"] .splide__arrows {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -50px;
    right: 40px;
  }

  [aria-label="Experts slides light"] .splide__slide span {
    width: 100%;
    cursor: pointer;
  }

  [aria-label="Experts slides light"] .splide__arrow--prev {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    border: #dde2e4 1px solid;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  [aria-label="Experts slides light"] .splide__arrow--next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border: #dde2e4 1px solid;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  [aria-label="Experts slides light"] .splide__arrow--prev:hover {
    background-color: #eef1f2;
  }

  [aria-label="Experts slides light"] .splide__arrow--next:hover {
    background-color: #eef1f2;
  }

  [aria-label="Experts slides light"] .splide__arrow--prev svg {
    position: relative;
    fill: #22282b;
    width: 14px;
  }

  [aria-label="Experts slides light"] .splide__arrow--next svg {
    position: relative;
    fill: #22282b;
    width: 14px;
  }

  [aria-label="Experts slides"] .splide__arrows {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -60px;
    right: 0px;
  }

  [aria-label="Experts slides"] .splide__slide span {
    width: 100%;
    cursor: pointer;
  }

  [aria-label="Experts slides"] .splide__arrow--prev {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    transform: rotate(180deg);
    border: #2a3336 1px solid;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  [aria-label="Experts slides"] .splide__arrow--next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border: #2a3336 1px solid;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  [aria-label="Experts slides"] .splide__arrow--prev:hover {
    background-color: #22282b;
  }

  [aria-label="Experts slides"] .splide__arrow--next:hover {
    background-color: #22282b;
  }

  [aria-label="Experts slides"] .splide__arrow--prev svg {
    position: relative;
    fill: #eef1f2;
    width: 14px;
  }

  [aria-label="Experts slides"] .splide__arrow--next svg {
    position: relative;
    fill: #eef1f2;
    width: 14px;
  }

  [aria-label="Media slides"] .splide__arrows {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -60px;
    right: 0px;
  }

  [aria-label="Media slides"] .splide__slide span {
    width: 100%;
    cursor: pointer;
  }

  [aria-label="Media slides"] .splide__arrow--prev {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    transform: rotate(180deg);
    border: #dde2e4 1px solid;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  [aria-label="Media slides"] .splide__arrow--next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border: #dde2e4 1px solid;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  [aria-label="Media slides"] .splide__arrow--prev svg {
    position: relative;
    fill: #2a3336;
    width: 14px;
  }

  [aria-label="Media slides"] .splide__arrow--next svg {
    position: relative;
    fill: #2a3336;
    width: 14px;
  }

  [aria-label="Media slides"] .splide__arrow--prev:hover {
    background-color: #eef1f2;
  }

  [aria-label="Media slides"] .splide__arrow--next:hover {
    background-color: #eef1f2;
  }
}

@media screen and (min-width: 1366px) {
  [aria-label="Experts slides light"] .splide__arrows {
    right: 15px;
  }
}

[aria-label="Twitter review"] .splide__arrows {
  display: none;
}

[aria-label="Twitter review"] [aria-roledescription="slide"] {
  width: 540px;
}

[aria-label="Platform slides"] .splide__arrows {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 1;
}

@media screen and (min-width: 1024px) {
  [aria-label="Platform slides"] .splide__arrows {
    bottom: 60px;
    left: 30px;
  }
}

[aria-label="Platform slides"] .splide__arrow--next {
  cursor: pointer;
}

[aria-label="Platform slides"] .splide__arrow--next svg {
  width: 14px;
}

[aria-label="Platform slides"] .splide__arrow--prev {
  margin-right: 30px;
}
@media screen and (min-width: 1024px) {
  [aria-label="Platform slides"] .splide__arrow--prev {
    margin-right: 50px;
    cursor: pointer;
  }
}

[aria-label="Platform slides"] .splide__arrow--prev svg {
  transform: rotate(180deg);
  width: 14px;
}
