.react-international-phone-flag-emoji
  .react-international-phone-country-selector-button__flag-emoji {
  display: none !important;
}

[aria-label="Phone input light theme"]
  .react-international-phone-input-container {
  background-color: #eef1f2;
  border-radius: 12px;
  color: #7e929a;
}

[aria-label="Phone input light theme"]
  .react-international-phone-country-selector-button {
  background-color: #eef1f2 !important;
  color: #7e929a !important;
}

[aria-label="Phone input light theme"] .react-international-phone-input {
  background-color: #eef1f2 !important;
  color: #7e929a !important;
  position: relative;
}

[aria-label="Phone input light theme"] .react-international-phone-input:focus {
  color: #22282b !important;
}

[aria-label="Phone input light theme"]
  .react-international-phone-country-selector-dropdown {
  background-color: #eef1f2 !important;
  border-radius: 12px !important;
  color: #7e929a !important;
}

[aria-label="Phone input light theme"]
  .react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: #22282b !important;
}

[aria-label="Phone input light theme"]
  .react-international-phone-country-selector-dropdown__list-item--focused {
  background-color: #22282b !important;
  color: #ffffff !important;
}

[aria-label="Phone input light theme"]
  .react-international-phone-country-selector-dropdown__list-item:hover
  .react-international-phone-country-selector-dropdown__list-item-country-name {
  color: #fff !important;
}

.react-international-phone-input-container {
  background-color: #22282b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  padding-left: 4px;
}
@media screen and (min-width: 1280px) {
  .react-international-phone-input-container {
    min-width: 350px;
  }
}

.react-international-phone-input {
  background-color: #22282b !important;
  border: 0 !important;
  border-radius: 14px !important;
  padding-right: 26px !important;
  padding-top: 26px !important;
  padding-bottom: 26px !important;
  color: #5e7178 !important;
  font-size: 16px !important;
}

.react-international-phone-input:focus {
  color: #ffffff !important;
}

.react-international-phone-country-selector-button {
  position: relative;
  left: 6px;
  top: 8px !important;
  background-color: #22282b !important;
  border: 0 !important;
  border-radius: 14px !important;
}

.react-international-phone-country-selector-dropdown {
  background-color: #22282b !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 14px !important;
  color: #ffffff !important;
}

.react-international-phone-country-selector-dropdown:focus {
  outline: none !important;
}

.react-international-phone-country-selector-button {
  position: relative;
  width: 20px;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  position: relative;
}

.react-international-phone-country-selector-button__button-content {
  width: inherit !important;
  height: inherit !important;
}

.react-international-phone-country-selector-dropdown__list-item:hover
  .react-international-phone-country-selector-dropdown__list-item-country-name {
  color: #22282b !important;
}

.react-international-phone-flag-emoji {
  display: none !important;
}

.react-international-phone-country-selector-dropdown {
  width: 350px !important;
  top: 55px !important;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  position: relative;
}

.react-international-phone-country-selector-button__dropdown-arrow::after {
  content: url(https://images.ctfassets.net/r0lccig03c53/1ishKnke4cLLn4mBEzKbzw/6374dfce344b8fb7836bbcf63dfe7fa4/arrow.svg);
  position: absolute;
  bottom: 18px;
  right: 5px;
  width: 0;
  height: 0;
}

.copyright
  .react-international-phone-country-selector-button__dropdown-arrow::after {
  bottom: 14px;
}
