@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Suisse";
  src: url("../../public/assets/suisse.woff");
  font-display: swap;
}

@font-face {
  font-family: "Spectral";
  src: url("../../public/assets/spectral.woff");
  font-display: swap;
}
@font-face {
  font-family: "Parabole";
  src: url("../../public/assets/parabole.woff");
  font-display: swap;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  padding: 0;
  margin: 0;
  font-family: "Suisse", Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
  background-color: #070809;
}

html {
  font-size: 18px;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1 {
  font-size: 52px;
  line-height: 96%;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 58px;
    line-height: 100%;
  }
}

@media screen and (min-width: 1280px) {
  h1 {
    font-size: 92px;
    line-height: 100%;
  }
}

h2 {
  font-size: 32px;
  line-height: 112%;
}

@media screen and (min-width: 768px) {
  h2 {
    font-size: 48px;
    line-height: 110%;
  }
}

@media screen and (min-width: 1024px) {
  h2 {
    font-size: 58px;
    line-height: 110%;
  }
}

h3 {
  font-size: 20px;
  line-height: 124%;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 28px;
    line-height: 1.1;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 38px;
    line-height: 41px;
  }
}

h4 {
  font-size: 22px;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  h4 {
    font-size: 28px;
    line-height: 110%;
  }
}

h5 {
  font-size: 22px;
  line-height: 24px;
}

@media screen and (min-width: 1024px) {
  h5 {
    font-size: 32px;
    line-height: 35px;
  }
}

h6 {
  font-size: 20px;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  h6 {
    font-size: 28px;
    line-height: 110%;
  }
}

button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls {
  display: none !important;
}

.grecaptcha-badge {
  opacity: 0;
}

.first-sentence {
  color: black;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

@keyframes slideFromLeftToRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(100%);
  }
}

.animation-slide {
  animation: slideFromLeftToRight 3s linear infinite;
}
